import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import countries from "../utilities/countries.json";
import Card from "../components/Card";
import Car from "../assets/Car.svg";
import { useWebClient } from "./provider";
// Form and validation
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { ChangeStatusFunc, viewDate } from "../utilities/functions";
import { apiUrl as baseUrl } from "../config";
const formSchema = Yup.object().shape({
  gateId: Yup.number(),
});
const RequestPage = ({ siteId, TransactionId, code }) => {
  //var notificationUrl = "https://staging.api.smartvalet.libancallplus.com";

  // var apiOrigin = notificationUrl;
  var hubUrl = baseUrl + "/notificationshub";
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(formSchema),
  });
  const [Error, setError] = useState("");
  const [connection, setConnection] = useState(0);

  const {
    state: { selected: Info },
    fetchOne: getInfo,
  } = useWebClient();

  useEffect(() => {
    try {
      getInfo(siteId, TransactionId, code);
    } catch (error) {
      setError("Error happen");
    }
  }, [getInfo, siteId, TransactionId, code]);
  const [StatusPage, setStatusPage] = useState();
  useEffect(() => {
    if (Info?.transactionInfo?.statusId == 1) {
      setStatusPage(false);
    } else if (Info?.transactionInfo?.statusId == 2) {
      setStatusPage(false);
    } else if (Info?.transactionInfo?.statusId == 3) {
      setStatusPage(true);
    } else if (Info?.transactionInfo?.statusId == 4) {
      setStatusPage(true);
    } else if (Info?.transactionInfo?.statusId == 5) {
      setStatusPage(true);
    }
  });
  const { RequestCar } = useWebClient();
  //handle success
  const handleSuccess = async () => {
    //navigate(`/${siteId}/${TransactionId}/${code}/statusCar`);
    setStatusPage(!StatusPage);
    await getInfo(siteId, TransactionId, code);
  };

  //Submit
  const onSubmit = async (values) => {
    try {
      await RequestCar(siteId, TransactionId, code, values);
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      setError(e.errors.message);
    }
  };
  // let connections;

  // useEffect(() => {
  //   connections = new HubConnectionBuilder().withUrl(hubUrl).build();
  // }, []);
  // useEffect(() => {
  //   connections = new HubConnectionBuilder().withUrl(hubUrl).build();
  //   //connections.hub.logging = true;

  //   connections.start().then(() => {
  //     /**on */
  //     connections.on("TransactionStatusChange", function (transactionId) {
  //       getInfo(siteId, TransactionId, code);

  //       console.log(StatusPage, "status on connection");
  //     });
  //   });

  //   connections.onclose(() => {
  //     connections.start().then(() => {
  //       getInfo(siteId, TransactionId, code);

  //       console.log(StatusPage, "status again");
  //     });
  //   });
  // }, []);
  // useEffect(() => {
  //   let newConnection = new HubConnectionBuilder()
  //     .withUrl(hubUrl)
  //     .withAutomaticReconnect()
  //     .build();
  //   alert("after start");
  //   newConnection
  //     .start()
  //     .then(() => {
  //       // getInfo(siteId, TransactionId, code);
  //       //newConnection.on("TransactionStatusChange", function (transactionId) {
  //       // Do what you want now... fetch new data from server
  //       //   getInfo(siteId, transactionId, code);
  //       // });
  //     })
  //     .catch((e) => console.log("Connection failed: ", e));
  // }, []);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(hubUrl)
      .withAutomaticReconnect()
      .build();
    connection
      .start()
      .then((result) => {
        getInfo(siteId, TransactionId, code);
        connection.on("TransactionStatusChange", function (transactionId) {
          getInfo(siteId, TransactionId, code);
        });
      })
      .catch((e) => console.log("Connection failed: ", e));
    connection.onclose(() => {
      connection.start().then(() => {
        getInfo(siteId, TransactionId, code);
      });
    });
    connection.onreconnected(() => {
      getInfo(siteId, TransactionId, code);
    });
  }, []);

  if (!Info) return;
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {Error && <p className="error">{Error}</p>}
      <div className="request-container">
        <Header
          logo={Info?.companyInfo?.logoUrl}
          companyTitle={Info?.companyInfo?.title}
          siteTitle={Info?.siteInfo?.title}
        />
        <div className="main">
          <div className="ticket-section">
            <div>
              {countries
                ?.filter((cc) => cc.name === Info?.siteInfo?.country)
                .map((c, index) => (
                  <div key={index} className="countryCode">
                    <span> {c.flag}</span>
                    {c.code}
                  </div>
                ))}
              <p className="ticket-font">
                {viewDate(Info?.transactionInfo?.checkIn)}
              </p>
            </div>
            <div className="right-section">
              <p className="ticket-font">Ticket</p>
              <div className="ticket-circle">
                {Info?.transactionInfo?.number}
              </div>
              {Info?.siteInfo?.showPrice !== 0 && (
                <p className="ticket-font">Fee</p>
              )}{" "}
              {Info?.siteInfo?.showPrice === 1 && (
                <div className="fee-circle">
                  {Info?.transactionInfo?.officialPrice}

                  <span>{Info?.siteInfo?.currency}</span>
                </div>
              )}{" "}
              {Info?.siteInfo?.showPrice === 2 && (
                <div className="fee-circle">
                  {Info?.transactionInfo?.trialPrice}

                  <span>{Info?.siteInfo?.currency}</span>
                </div>
              )}
              {Info?.siteInfo?.showPrice === 0 && <></>}
            </div>
          </div>
          <div className="text-section">
            <p>{Info?.siteInfo?.caption}</p>
            <div className="content">
              {Info?.siteInfo?.leftText && (
                <div className="left-text">
                  <p>{Info?.siteInfo?.leftText}</p>
                </div>
              )}
              {Info?.siteInfo?.rightText && (
                <div className="right-text">
                  <p>{Info?.siteInfo?.rightText}</p>
                </div>
              )}
            </div>
          </div>
          {Info?.siteInfo.gates?.length !== 0 && !StatusPage ? (
            <div className="select-section">
              <select {...register("gateId")}>
                {Info.siteInfo?.gates?.map((gate, index) => (
                  <option key={index} value={gate?.id}>
                    {gate?.title}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            ""
          )}
          <div className="request-section">
            {!StatusPage ? (
              <button className="card-button">
                <div className="request-card">
                  <div className="head-section">
                    <div className="img-wrapper">
                      <img src={Car} alt="car-img" />
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <button>
                      <span>Request vehicle</span>
                    </button>
                  </div>
                </div>
              </button>
            ) : (
              <div className="status-card">
                <div className="head-section">
                  <div className="img-wrapper">
                    <img src={Car} alt="car-img" />
                  </div>
                </div>
                <div className="status-section">
                  {Info?.transactionInfo?.statusId === 1 ||
                    Info?.transactionInfo?.statusId === 2 ||
                    (Info?.transactionInfo?.statusId === 3 && (
                      <p>
                        <span
                          style={{
                            color:
                              Info?.transactionInfo?.paidStatusId === 1
                                ? "red"
                                : "green",
                          }}
                        >
                          Requested
                        </span>
                      </p>
                    ))}
                  {Info?.transactionInfo?.statusId === 4 && (
                    <p>
                      <span
                        style={{
                          color:
                            Info?.transactionInfo?.paidStatusId === 1
                              ? "red"
                              : "green",
                        }}
                      >
                        on the road
                      </span>
                    </p>
                  )}
                  {Info?.transactionInfo?.statusId === 5 && (
                    <p>
                      <span
                        style={{
                          color:
                            Info?.transactionInfo?.paidStatusId === 1
                              ? "red"
                              : "green",
                        }}
                      >
                        Your car is ready!{" "}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </form>
  );
};

export default RequestPage;
